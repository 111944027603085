#Price{
    background: rgb(222,207,198);
    background: -moz-linear-gradient(0deg, rgba(222,207,198,1) 0%, rgba(230,184,157,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(222,207,198,1) 0%, rgba(230,184,157,1) 100%);
    background: linear-gradient(0deg, rgba(222,207,198,1) 0%, rgba(230,184,157,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#decfc6",endColorstr="#e6b89d",GradientType=1);  

    padding-top: 2%;
    padding-bottom: 2%;
    padding-left:14%;
    padding-right: 14%;
    height: 350px;
}

#PriceTitle{
    text-align: center;
    font-size: 45px;
    color: black;
    padding-bottom: 2%;
    font-weight: bold;
}

#PriceText{
    text-align: justify;
    font-size: 22px;
    font-weight: 550;
    color: black;
}

#PriceImg{
    float:right;
    border-radius: 20px;
    margin-left: 20px;
}

#PriceImgMobile{
    display: none;
}

@media screen and (max-width: 1400px){

    #PriceTitle{
        font-size: 35px;
    }

    #PriceText{
        text-align: justify;
        font-size:16px;
    }

    #Price{
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left:7%;
        padding-right: 7%;
        height: 350px;
    }
}

@media screen and (max-width: 1060px){
    #PriceText{
        text-align: justify;
        font-size:15px;
    }

    #Price{  
    
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left:8%;
        padding-right: 8%;
        height: 350px;
    }
}

@media screen and (max-width: 960px){
    #PriceText{
        text-align: justify;
        font-size:16px;
    }

    #PriceImg{
        display: none;
    }

    #PriceImgMobile{
        display: block;
        margin-top: 10px;
        border-radius: 20px;
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #Price{
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left:16%;
        padding-right: 16%;
        height: 480px;
    }
}