#HIW{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(230,184,157,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(230,184,157,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(230,184,157,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e6b89d",GradientType=1);     
}

#HIWTitle{
    text-align: center;
    font-size: 3.5vw;
    color: black;
    font-weight: bold;
    padding-bottom: 30px;
}

.number{
    font-size: 18px;
    border-radius: 50%;
    background-color: darkslategray;
    width: 60px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}

@media screen and (max-width: 1170px){
    .number{
        height: 40px;
    }

    #title{
        font-size: 5vw;
    }

    #HIWTitle{
        font-size: 35px;
    }
}